import React from "react";

import { Box, Typography } from "@mui/material";

const Footer = () => {
  return (
    <footer className="footer">
      <Box>
        <Typography>Made with ❤️ by Philippe Turner</Typography>
      </Box>
    </footer>
  );
};

export default Footer;
