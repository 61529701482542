import React from "react";

import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";


const extractStatsFromAvailablePlayers = (data: any, filterEmpty: boolean, genericName: string, includeOverall: boolean) => {
  const stats = Object.entries(data)
    .filter(
      ([_, value]) => value && (value as any).name && (value as any).stats
    )
    .map(([key, value]) => {
      const preliminaryStats = {
        id: key,
        name: (value as any).name,
        numberOfGame: (value as any).stats.numberOfGame as number,
        totalRoll: (value as any).stats.totalRoll as number,
        maxNumberOfRoll: (value as any).stats.maxNumberOfRoll as number
      };
      if (
        preliminaryStats.numberOfGame === 0 ||
        preliminaryStats.totalRoll === 0
      ) {
        return { ...preliminaryStats, winRate: 0, averageLength: 0 };
      }
      return {
        ...preliminaryStats,
        winRate:
          (preliminaryStats.numberOfGame / preliminaryStats.totalRoll) * 100,
        averageLength:
          preliminaryStats.totalRoll / preliminaryStats.numberOfGame
      };
    })
    .filter((x) => x.numberOfGame > 0 || !filterEmpty);

  const preliminaryTeamStats = {
    id: "0",
    name: genericName,
    numberOfGame: stats.reduce((x, y) => x + y.numberOfGame, 0),
    totalRoll: stats.reduce((x, y) => x + y.totalRoll, 0),
    maxNumberOfRoll: stats.reduce((x, y) => Math.max(x, y.maxNumberOfRoll), 0)
  };
  if (!includeOverall) {
    return stats;
  }
  let teamStats;
  if (
    preliminaryTeamStats.numberOfGame === 0 ||
    preliminaryTeamStats.totalRoll === 0
  ) {
    teamStats = { ...preliminaryTeamStats, winRate: 0, averageLength: 0 };
  } else {
    teamStats = {
      ...preliminaryTeamStats,
      winRate:
        (preliminaryTeamStats.numberOfGame / preliminaryTeamStats.totalRoll) *
        100,
      averageLength:
        preliminaryTeamStats.totalRoll / preliminaryTeamStats.numberOfGame 
    };
  }

  stats.push(teamStats);
  return stats;
}

const Stats = (props: {
  availablePlayers: any;
  genericName: string;
  filterEmpty: boolean;
  dataKey: string,
  name: string,
  includeOverall: boolean,
  domain: number[] | undefined
}) => {
  const navigate = useNavigate();

  const stats = extractStatsFromAvailablePlayers(
    props.availablePlayers,
    props.filterEmpty,
    props.genericName,
    props.includeOverall
  );

  const theme = useTheme();

  return (
    <>
    <Typography variant="h6" component="h6" align="center">{props.name}</Typography>
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={stats}
        margin={{
          top: 10,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" angle={45} />
        <YAxis domain={props.domain} />
        <Tooltip />
        <Bar
          dataKey={props.dataKey}
          name={props.name}
          fill={theme.palette.secondary.main}
          onClick={(e) => {
            const { payload } = e;
            if (payload && payload.id && payload.id != 0) {
              navigate("/profile?id=" + payload.id);
            }
          }}
        />
      </BarChart>
    </ResponsiveContainer>
    </>
  );
};

export default Stats;
