import React from "react";
import { Container } from "@mui/system";
import { useQuery } from "react-query";
import { Box, CircularProgress, Typography } from "@mui/material";
import Stats from "../components/LoisDesNorms/Stats";

const LoisDesNormsStats = () => {
  const statsQuery = useQuery("all-player-stats", async () => {
    const result = await fetch(process.env.REACT_APP_HTTP_ENDPOINT + "/stats");
    const jsonResult = await result.json();
    const formattedStats: any = {};
    for (const entry of jsonResult) {
      formattedStats[entry.id] = entry;
    }
    return formattedStats;
  });

  if (statsQuery.isLoading) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <Typography variant="h4" component="h4" align="center">
        Lois Des Norms Stats
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: 320,
          padding: "10px",
          minHeight: "446px",
        }}
      >
        <Stats
          availablePlayers={statsQuery.data}
          filterEmpty={true}
          genericName="Overall"
          dataKey="winRate"
          name="Winrate"
          includeOverall={true}
          domain={[0, 100]}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: 320,
          padding: "10px",
          minHeight: "446px",
        }}
      >
        <Stats
          availablePlayers={statsQuery.data}
          filterEmpty={true}
          genericName="Overall"
          dataKey="averageLength"
          name="Average Length"
          includeOverall={false}
          domain={undefined}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: 320,
          padding: "10px",
          minHeight: "446px",
        }}
      >
        <Stats
          availablePlayers={statsQuery.data}
          filterEmpty={true}
          genericName="Overall"
          dataKey="maxNumberOfRoll"
          name="Max number of roll"
          includeOverall={false}
          domain={undefined}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: 320,
          padding: "10px",
          minHeight: "446px",
        }}
      >
        <Stats
          availablePlayers={statsQuery.data}
          filterEmpty={true}
          genericName="Overall"
          dataKey="numberOfGame"
          name="Number of Game"
          includeOverall={false}
          domain={undefined}
        />
      </Box>
    </Container>
  );
};

export default LoisDesNormsStats;
